<div class="login-container" style="position: relative; padding: 20px;">
    <!-- Card in the top-left corner -->
    <div class="info-card" style="
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 15px;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        background-color: #f9f9f9;
        width: 300px;
        font-family: Arial, sans-serif;
    ">
        <h3 style="margin: 0; font-size: 18px; color: #333;">Connectorzzz</h3>
        <p style="margin: 5px 0; font-size: 14px; color: #555;">
            <strong>Company:</strong> Connectorzzz<br>
            <strong>Domain:</strong> connectorzzz.com<br>
            <strong>Email:</strong> thaer.saidi@outlook.com<br>
            <strong>Phone:</strong> +33 745398509<br>
            <strong>Address:</strong> 16 rue Ellianne Jeannine Garreau, Paris, France
        </p>
    </div>

    <!-- Background image -->
    <div class="background-image" class="image-bg"></div>

    <!-- Login button -->
    <button mat-raised-button (click)="loginWithGoogle()" style="margin-top: 100px;">
        <i class="fab fa-google fa-2x" style="color: #dd4b39;"></i>
        Login with Google
    </button>

    <!-- Uncomment for Facebook login -->
    <!-- <button mat-raised-button (click)="loginWithFacebook()">
        <i class="fab fa-facebook-f fa-2x" style="color: #3b5998;"></i>
        Login with Facebook
    </button> -->
</div>
